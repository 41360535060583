<template>
     <CNavGroup>
        <template #togglerContent>
          <CIcon  customClassName="nav-icon" icon="cil-cursor"/> {{navDetails.template_name}}
        </template>
        <CNavItem  v-for="(item,index) in navDetails.nav_items" :key="index" :href=item.url>
           {{item.name}}
        </CNavItem>
      </CNavGroup>
</template>
   
<script>
import { ref } from 'vue'
export default {
    name: 'sideBarNav',
    props: ['role','inner_role'],
    setup (props) {
        var navDetails = ref({});
        navDetails.value.nav_items = [];
        if(props.role == "lms"){
            navDetails.value.template_name = 'LMS'
            navDetails.value.nav_items.push({ "name" : "Courses", "url": "#/lms/courses"})
            navDetails.value.nav_items.push({ "name" : "Microdegree", "url": "#/lms/microdegree"})
            navDetails.value.nav_items.push({ "name" : "Lms feedback", "url": "#/lms/feedback"})
            navDetails.value.nav_items.push({ "name" : "Lms Session Completion Feedback", "url": "#/lms/session/completion/feedback"})
            navDetails.value.nav_items.push({ "name" : "Users Submission Detail", "url": "#/lms/user/submission"})
        }else if(props.role == "lms_analytics"){
            navDetails.value.template_name = 'LMS Analytics'
            navDetails.value.nav_items.push({ "name" : "Session Schedule", "url": "#/lms-analytics/session/schedule"})
            navDetails.value.nav_items.push({ "name" : "Session Attendance", "url": "#/lms-analytics/session/attendance"})
            navDetails.value.nav_items.push({ "name" : "Session Reschedule", "url": "#/lms-analytics/session/reschedule"})
        }else if(props.role == "app_product"){
            navDetails.value.template_name = 'App Product Data'
            navDetails.value.nav_items.push({ "name" : "Products", "url": "#/app-product-data"})
        }else if(props.role == "product"){
            navDetails.value.template_name = 'Products'
            if(props.inner_role == 'registration'){
                navDetails.value.nav_items.push({ "name" : "Product Registration", "url": "#/products/registration"})
            }else{
                navDetails.value.nav_items.push({ "name" : "Product Listing", "url": "#/products/listing"})
                navDetails.value.nav_items.push({ "name" : "Accessory Listing", "url": "#/accessories/listing"})
            }
        }else if(props.role == "curriculum"){
            navDetails.value.template_name = "Curriculum"
            navDetails.value.nav_items.push({ "name" : "Category", "url": "#/curriculum/category/listing"})
            navDetails.value.nav_items.push({ "name" : "Quiz", "url": "#/curriculum/quiz/listing"})
            navDetails.value.nav_items.push({ "name" : "Generic Checkpoint", "url": "#/curriculum/generic/checkpoint/listing"})
        }else if(props.role == "production"){
            navDetails.value.template_name = "Production"
            navDetails.value.nav_items.push({ "name" : "Bulk Qr", "url": "#/production/bulk/qr"})
            navDetails.value.nav_items.push({ "name" : "Brain Testing", "url": "#/production/brain/testing"})
            navDetails.value.nav_items.push({ "name" : "Return Brain Testing", "url": "#/production/return/brain/testing"})
            navDetails.value.nav_items.push({ "name" : "Sensor Testing", "url": "#/production/sensor/testing"})
            navDetails.value.nav_items.push({ "name" : "Brain Qr Download", "url": "#/production/brain/qr/download"})
            navDetails.value.nav_items.push({ "name" : "Make Finished Stock", "url": "#/production/make/finished/stock"})
            navDetails.value.nav_items.push({ "name" : "Finished Stock Listing", "url": "#/production/finished/stock/listing"})
            navDetails.value.nav_items.push({ "name" : "Make Semi Finished Stock", "url": "#/production/make/semi-finished/stock"})
            navDetails.value.nav_items.push({ "name" : "Semi Finished Stock Listing", "url": "#/production/semi-finished/stock/listing"})
            navDetails.value.nav_items.push({ "name" : "Make Wip2", "url": "#/production/make/wip2"})
            navDetails.value.nav_items.push({ "name" : "Wip2 Listing", "url": "#/production/wip2/stock/listing"})
        }else if(props.role == "raw_material"){
            navDetails.value.template_name = "Raw Material"
            if(!props.inner_role){
                navDetails.value.nav_items.push({ "name" : "Material Types", "url": "#/raw-material/type"})
                navDetails.value.nav_items.push({ "name" : "Material Components", "url": "#/raw-material/component"})
                navDetails.value.nav_items.push({ "name" : "Add Component Quantity", "url": "#/raw-material/update-material-quantity"})
                navDetails.value.nav_items.push({ "name" : "WIP1 Bom", "url": "#/raw-material/wip1-bom"})
                navDetails.value.nav_items.push({ "name" : "WIP2 Bom", "url": "#/raw-material/wip2-bom"})
                navDetails.value.nav_items.push({ "name" : "SF Goods", "url": "#/raw-material/semi-finished-bom"})
                navDetails.value.nav_items.push({ "name" : "Finished Goods", "url": "#/raw-material/finished-bom"})
                navDetails.value.nav_items.push({ "name" : "Minimum Stock View", "url": "#/raw-material/minimum/stock/view"})
                navDetails.value.nav_items.push({ "name" : "Critical Stock View", "url": "#/raw-material/critical/stock/view"})
            }
            if(props.inner_role == 'purchase' || !props.inner_role){
                navDetails.value.nav_items.push({ "name" : "Vendors", "url": "#/raw-material/vendor"})
                navDetails.value.nav_items.push({ "name" : "Purchase Planning", "url": "#/raw-material/required/quantity"})
            }
        }else if(props.role == "projects"){
            navDetails.value.template_name = "Projects"
            navDetails.value.nav_items.push({ "name" : "Analytics", "url": "#/projects/analytics"})
            navDetails.value.nav_items.push({ "name" : "Pending Projects", "url": "#/projects/pending"})
            navDetails.value.nav_items.push({ "name" : "Approved Projects", "url": "#/projects/approved"})
            navDetails.value.nav_items.push({ "name" : "Disapproved Projects", "url": "#/projects/disapproved"})
        }else if(props.role == "event"){
            navDetails.value.template_name = 'Events'
            if(props.inner_role == 'registration'){
                navDetails.value.nav_items.push({ "name" : "Team Edit", "url": "#/events/editTeam"})
            }else{    
                navDetails.value.nav_items.push({ "name" : "Create Event", "url": "#/events/create"})
                navDetails.value.nav_items.push({ "name" : "Tasks", "url": "#/events/tasks"})
                navDetails.value.nav_items.push({ "name" : "Event Listing", "url": "#/events/listing"})
                navDetails.value.nav_items.push({ "name" : "Team Edit", "url": "#/events/editTeam"})
            }
        }else if(props.role == "partner_admin"){
            navDetails.value.template_name = "Partner"
            navDetails.value.nav_items.push({ "name" : "Partner Listing", "url": "#/partner/listing"})
        }else if(props.role == "notification_admin"){
            navDetails.value.template_name = "Notifications"
            navDetails.value.nav_items.push({ "name" : "Create Notification", "url": "#/notifications/create"})
            navDetails.value.nav_items.push({ "name" : "Listing Notification", "url": "#/notifications/listing"})
        }else if(props.role == "order_form"){
            navDetails.value.template_name = "Order Form"
            navDetails.value.nav_items.push({ "name" : "Order Listing", "url": "#/orders/listing"})
        }else if(props.role == "blogs"){
            navDetails.value.template_name = "Blogs"
            navDetails.value.nav_items.push({ "name" : "Blog Listing", "url": "#/blogs/listing"})
            navDetails.value.nav_items.push({ "name" : "Create Blog", "url": "#/blogs/create"})
        }else if(props.role == "inside_sales"){
            navDetails.value.template_name = "Inside Sales"
            navDetails.value.nav_items.push({ "name" : "Inside Sales Listing", "url": "#/purchase/listing"})
            navDetails.value.nav_items.push({ "name" : "Create URL", "url": "#/purchase/create/url"})
        }else if(props.role == "license_admin"){
            navDetails.value.template_name = "Licenses"
            navDetails.value.nav_items.push({ "name" : "License Listing", "url": "#/license/listing"})
        }else if(props.role == "sku"){
            navDetails.value.template_name = "Avishkaar SKU"
            navDetails.value.nav_items.push({ "name" : "SKU Listing", "url": "#/sku/listing"})
            navDetails.value.nav_items.push({ "name" : "Add SKU", "url": "#/sku/add/product"})
        }else if(props.role == "pipeline"){
            navDetails.value.template_name = "Avishkaar Release"
            navDetails.value.nav_items.push({ "name" : "Shop Release", "url": "#/release/shop"})
        }else if(props.role == "course"){
            navDetails.value.template_name = "Courses"
            navDetails.value.nav_items.push({ "name" : "Course Listing", "url": "#/course/listing"})
        }else if(props.role == "inventory"){
            navDetails.value.template_name = "Inventory"
            if(props.inner_role == 'production'){
                navDetails.value.nav_items.push({ "name" : "Place Order", "url": "#/inventory/place/order"})
            }
            if(props.inner_role == 'store'){
                navDetails.value.nav_items.push({ "name" : "Add Stock", "url": "#/inventory/add/stock"})
            }
            navDetails.value.nav_items.push({ "name" : "Order Listing", "url": "#/inventory/order/listing"})
            navDetails.value.nav_items.push({ "name" : "Stock", "url": "#/inventory/stock"})
        }else if(props.role == "ams_play"){
            navDetails.value.template_name = "AMS Play"
            navDetails.value.nav_items.push({ "name" : "AMS Play Types", "url": "#/ams-play/types"})
        }else if(props.role == "community_dashboard"){
            navDetails.value.template_name = "Community"
            navDetails.value.nav_items.push({ "name" : "Dashboard", "url": "#/community/dashboard"})
        }else if(props.role == 'service_request'){
            navDetails.value.template_name = "Service Request"
            navDetails.value.nav_items.push({ "name" : "Request Listing", "url": "#/service/request/listing"})
        }else if(props.role == "avishkaar_popup"){
            navDetails.value.template_name = "Avishkaar Pop-ups"
            navDetails.value.nav_items.push({ "name" : "Pop-ups Listing", "url": "#/popup/listing"})
            navDetails.value.nav_items.push({ "name" : "Create Pop-ups", "url": "#/popup/create"})
        }
        return { navDetails}
    } 
}
</script>
   